import axios from 'axios';
import { NewsReport } from '../../common/models/NewsReport';

const apidev = `https://api-dev.microcap.uk`;
const apiprod = `https://api.microcap.uk`;

export async function getNews(daystring:string) : Promise<NewsReport> {
    let nr:NewsReport = {} as any;
    try {
        if(!daystring) {
            return nr;
        }
        const api = process.env.REACT_APP_ENV as string === "prod" ? apiprod : apidev;
        const resp = await axios.get(`${api}/news/${daystring}`);
        return resp.data as NewsReport;
    } catch (error) {
        console.error(JSON.stringify(error))   
    }
    return nr;    
}

export async function getAccessKey() : Promise<string> {
    try {
        const api = process.env.REACT_APP_ENV as string === "prod" ? apiprod : apidev;
        const resp = await axios.get(`${api}/key`);
        const rd = resp.data as any;
        return rd.key;    
    } catch (error) {
        console.error(JSON.stringify(error))   
    }
    return "";    
}