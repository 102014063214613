import { Chip, createStyles, Divider, Grid, makeStyles, Paper, Slider, TextField, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DoneIcon from '@material-ui/icons/Done';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Pagination } from '@material-ui/lab';
import DaySelector from './DaySelector';

interface Props {
    onMcapFilter: (mcap: Number) => void;
    onSectorFilter: (sector: any) => void;
    onDateFilter: (sector: any) => void;
    defaultMarketCap:Number;
    defaultSector:any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            margin: '0',
            backgroundColor: '#F7F9FA',
            padding: theme.spacing(1),
            height: '100%'
        },
        mcap: {
            width: '55%',
        },
        filterTitle: {
            textAlign: 'left',
        },
        chip: {
            margin: theme.spacing(0.5),
        },
        chipRoot: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            padding: theme.spacing(0.5),
            margin: 0,
        },
        daySelect: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            justifyContent: 'center',
            justifyItems: 'center',
            margin: 0,
            display: 'flex',
            // width: '100%',
        }
    })
);

const marks = [
    {
        value: 10,
        label: '< 10M',
    },
    {
        value: 20,
        label: '< 20M',
    },
    {
        value: 30,
        label: '< 30M',
    },
    {
        value: 40,
        label: '< 40M',
    },
    {
        value: 50,
        label: '< 50M',
    },
];

function valuetext(value: any) {
    return `${value}M`;
}

const FilterPannel: React.FC<Props> = ({ onMcapFilter, onSectorFilter, onDateFilter, defaultMarketCap, defaultSector}) => {
    
    const classes = useStyles();
    const [mcap, setMcap] = useState<Number>(defaultMarketCap);
    const [dateString, setDateString] = useState<string>();
    const [sector, setSectorFilter] = useState(defaultSector ? defaultSector : 
                                                [
                                                    { label: 'Commercial Services', selected: true },
                                                    { label: 'Communications', selected: true },
                                                    { label: 'Consumer Durables', selected: true },
                                                    { label: 'Consumer Non-Durables', selected: true },
                                                    { label: 'Consumer Services', selected: true },
                                                    { label: 'Distribution Services', selected: true },
                                                    { label: 'Electronic Technology', selected: true },
                                                    { label: 'Energy Minerals', selected: true },
                                                    { label: 'Finance', selected: true },
                                                    { label: 'Government', selected: true },
                                                    { label: 'Health Services', selected: true },
                                                    { label: 'Health Technology', selected: true },
                                                    { label: 'Industrial Services', selected: true },
                                                    { label: 'Miscellaneous', selected: true },
                                                    { label: 'Non-Energy Minerals', selected: true },
                                                    { label: 'Process Industries', selected: true },
                                                    { label: 'Producer Manufacturing', selected: true },
                                                    { label: 'Retail Trade', selected: true },
                                                    { label: 'Technology Services', selected: true },
                                                    { label: 'Transportation', selected: true },
                                                    { label: 'Utilities', selected: true },
                                                ]
    );
    
    const handleSelectSector = (data:any) => {
        const c = [...sector];
        sector.forEach(cd => {
            if(cd.label === data.label) {
                c[c.indexOf(cd)].selected = !cd.selected;
            }
        });
        setSectorFilter(c);
    }

    const handleMcapChange = (event:any, newValue:any) => {
        const mcap = Number(newValue) * 1000000
        setMcap(mcap);
    };

    const handleDayChange = (datestring:string) => {
        setDateString(datestring)
    };

    useEffect(() => {
        onMcapFilter(mcap);
    },[mcap]);

    useEffect(() => {
        onSectorFilter(sector);
    },[sector]);

    useEffect(() => {
        onDateFilter(dateString);
    },[dateString]);

    return (
        <Grid container spacing={3} >
            <Paper className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={1} sm={2} lg={2}/>
                    <Grid item xs={10} sm={8} lg={8} className={classes.daySelect}>
                        <Paper >
                            <DaySelector onChange={handleDayChange}/>
                        </Paper>
                    </Grid>
                    <Grid item xs={1} sm={2} lg={2}/>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default FilterPannel;
