import { Box, Button, createStyles, Divider, Grid, IconButton, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

interface Props {
    onChange: (datestring: string) => void;
}

const useStyles = makeStyles((theme) => ({
    daySelect: {
        alignContent: 'center',
        width: '100%'
    },
}));

export interface DateFilter {
    Label: string;
    Value: string;
    Selected: boolean;
}

const DaySelector: React.FC<Props> = ({ onChange }) => {

    const [dateFilters, setDateFilters] = useState<Array<DateFilter>>([]);
    const classes = useStyles();

    const handleClick = (data:DateFilter) => {
        const tt =  [...dateFilters];
        tt.forEach(t => {
            t.Selected = false;
            if(t.Label == data.Label) {
                t.Selected = true;
            }
        });
        setDateFilters(tt);
    }

    useEffect(() => {
        setDateFilters(
            buildDays()
        );
    }, []);

    useEffect(() => {
        dateFilters.forEach(df => {
            if(df.Selected) {
                onChange(df.Value)
            }
        });
    }, [dateFilters]);

    function buildDays() {
        const df:Array<DateFilter> = [];
        const start = new Date();
        do
        {
            if(start.getDay() > 0 && start.getDay() < 6) {
                const dn = getFormattedDate(start);
                const dd = start.getDate().toString();
                const mm = (start.getMonth() + 1).toString();
                const yyyy = start.getFullYear().toString();
                df.push({
                    Label: `${dn}`,
                    Value: `${dd}${mm}${yyyy}`,
                    Selected: false,
                });
            }
            start.setDate(start.getDate()-1);
        }
        while(df.length < 5)

        if(start.getDay() > 0 && start.getDay() < 6) {
            df[0].Label = "Today";
        }
        
        df[0].Selected = true;
        df.reverse();

        return df;
    }

    function getOrdinalNum(n) {
        return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    }

    function getFormattedDate(date:Date) {
        var dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        var dayOfWeekIndex = date.getDay()
        return dayNames[dayOfWeekIndex];
    }

    return(
        <Box>
            <Grid container>
                <Paper className={classes.daySelect}>
                {
                    dateFilters.map((df) => {
                        return(
                            <IconButton color={df.Selected ? "primary" : "default"} component="span" onClick={()=>handleClick(df)}>
                                <Typography>
                                    {df.Selected ? <b>{df.Label}</b> : df.Label}
                                </Typography>
                                <Divider orientation="vertical" />
                            </IconButton>   
                        )
                    })
                }
                </Paper>
            </Grid>
        </Box>
    )

}

export default DaySelector;
